<!--
--------------------------------------------------------------------------
   Search.vue
--------------------------------------------------------------------------

    Component to build search page

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="search">
        <h1><translate>Search</translate></h1>
        <template v-if="type === ''">
            <loading v-if="loading !== 0"></loading>
            <h2><translate>People</translate></h2>
            <div class="search-results" v-if="loading === 0 && results.person.length > 0">
                <ul class="list">
                    <b-media tag="li" right-align vertical-align="center" v-for="item in results.person" :key="'item.' + item.id" :id="'item-' + item.id" :class="'item-' + item.type + '-' + item.subtype">
                        <h5 class="mt-0">
                            <router-link :to="getItemURL(item).replace($store.state.config.URL, '')">{{ getItemFieldValue(item, 'title', $language.current, false) }}</router-link>
                            <i class="fas fa-lock-open open-access" v-if="getItemFieldValue(item, 'openAccess', $language.current, false) === '1'"></i>
                        </h5>
                        <p v-html="printItemDescription(item, null)"></p>
                        <itemListTools :item="item" v-if="item.type === 'person' || item.type === 'activity'"></itemListTools>
                    </b-media>
                </ul>
                <b-button variant="primary" v-if="formData.page.totals.person > 5" class="mt-3" @click="seeAll('person')">
                    <i class="fas fa-plus"></i> <translate>See all results</translate>
                </b-button>
            </div>
            <b-alert show variant="danger" class="mt-4" v-if="loading === 0 && results.person.length === 0">
                <translate>No results found.</translate>
            </b-alert>

            <h2 class="mt-5"><translate>Organization</translate></h2>
            <div class="search-results" v-if="loading === 0 && results.organization.length > 0">
                <ul class="list">
                    <b-media tag="li" right-align vertical-align="center" v-for="item in results.organization" :key="'item.' + item.id" :id="'item-' + item.id" :class="'item-' + item.type + '-' + item.subtype">
                        <h5 class="mt-0">
                            <router-link :to="getItemURL(item).replace($store.state.config.URL, '')">{{ getItemFieldValue(item, 'title', $language.current, false) }}</router-link>
                            <i class="fas fa-lock-open open-access" v-if="getItemFieldValue(item, 'openAccess', $language.current, false) === '1'"></i>
                        </h5>
                        <p v-html="printItemDescription(item, null)"></p>
                        <itemListTools :item="item" v-if="item.type === 'person' || item.type === 'activity'"></itemListTools>
                    </b-media>
                </ul>
                <b-button variant="primary" v-if="formData.page.totals.organization > 5" class="mt-3" @click="seeAll('organization')">
                    <i class="fas fa-plus"></i> <translate>See all results</translate>
                </b-button>
            </div>
            <b-alert show variant="danger" class="mt-4" v-if="loading === 0 && results.organization.length === 0">
                <translate>No results found.</translate>
            </b-alert>

            <h2 class="mt-5"><translate>Activity</translate></h2>
            <div class="search-results" v-if="loading === 0 && results.activity.length > 0">
                <ul class="list">
                    <b-media tag="li" right-align vertical-align="center" v-for="item in results.activity" :key="'item.' + item.id" :id="'item-' + item.id" :class="'item-' + item.type + '-' + item.subtype">
                        <h5 class="mt-0">
                            <router-link :to="getItemURL(item).replace($store.state.config.URL, '')">{{ getItemFieldValue(item, 'title', $language.current, false) }}</router-link>
                            <i class="fas fa-lock-open open-access" v-if="getItemFieldValue(item, 'openAccess', $language.current, false) === '1'"></i>
                        </h5>
                        <p v-html="printItemDescription(item, null)"></p>
                        <itemListTools :item="item" v-if="item.type === 'person' || item.type === 'activity'"></itemListTools>
                    </b-media>
                </ul>
                <b-button variant="primary" v-if="formData.page.totals.activity > 5" class="mt-3" @click="seeAll('activity')">
                    <i class="fas fa-plus"></i> <translate>See all results</translate>
                </b-button>
            </div>
            <b-alert show variant="danger" class="mt-4" v-if="loading === 0 && results.activity.length === 0">
                <translate>No results found.</translate>
            </b-alert>

            <h2 class="mt-5"><translate>Journal</translate></h2>
            <div class="search-results" v-if="loading === 0 && results.journal.length > 0">
                <ul class="list">
                    <b-media tag="li" right-align vertical-align="center" v-for="item in results.journal" :key="'item.' + item.id" :id="'item-' + item.id" :class="'item-' + item.type + '-' + item.subtype">
                        <h5 class="mt-0">
                            <router-link :to="getItemURL(item).replace($store.state.config.URL, '')">{{ getItemFieldValue(item, 'title', $language.current, false) }}</router-link>
                            <i class="fas fa-lock-open open-access" v-if="getItemFieldValue(item, 'openAccess', $language.current, false) === '1'"></i>
                        </h5>
                        <p v-html="printItemDescription(item, null)"></p>
                        <itemListTools :item="item" v-if="item.type === 'person' || item.type === 'activity'"></itemListTools>
                    </b-media>
                </ul>
                <b-button variant="primary" v-if="formData.page.totals.journal > 5" class="mt-3" @click="seeAll('journal')">
                    <i class="fas fa-plus"></i> <translate>See all results</translate>
                </b-button>
            </div>
            <b-alert show variant="danger" class="mt-4" v-if="loading === 0 && results.journal.length === 0">
                <translate>No results found.</translate>
            </b-alert>
        </template>
        <template v-else>
            <items :item="null" :section="null" :simulation="null" :searchType="type" :showFeatured="false" :featuredDefault="false" :showTitle="false"></items>
        </template>
    </div>
</template>

<script>
    import Items from "@/components/Items";
    import Loading from "@/components/Loading";
    import ItemListTools from "@/components/ItemListTools";

    export default {
        name: 'search',
        components: { Items, Loading, ItemListTools },
        data() {
            return {
                type: '',
                loading: -4,
                formData: {
                    text: '',
                    query: '',
                    sort: '',
                    facets: [],
                    page : {
                        pageSize: 5,
                        pageIdx: 0,
                        totals: {
                            person: 0,
                            organization: 0,
                            activity: 0,
                            journal: 0
                        }
                    }
                },
                results: {
                    person: [],
                    organization: [],
                    activity: [],
                    journal: []
                }
            }
        },
        mounted() {
            // Set base path
            this.$store.commit('setCurrentPath', '/search');

            // Set breadcrumbs
            this.$store.commit('setBreadcrumbs', [{ text: this.$gettext('Home'), to: '/' }, { text: this.$gettext('Search'), active: true }]);

            // Set metadata
            this.setMetadata(this.$gettext('Search') + ' | ' + this.$gettext(this.$store.state.metadata.title), 'Search results page', this.$gettext('search') + ',' + this.$gettext(this.$store.state.metadata.keywords));

            this.init();
        },
        methods: {
            /**
             * Inits search data and parameters
             */
            init() {
                this.processParametersQuery();
                if (this.type === '') this.search();
            },
            /**
             * Execute items search by type
             */
            search() {
                // Set loading
                this.loading = -4;

                // Execute search for every item type
                Object.keys(this.results).forEach(t => {
                    // Search items
                    let formData = Object.assign({}, this.formData);
                    formData.query = formData.query.replaceAll('{type}', t);
                    if (t !== 'activity') formData.sort = t + '_' + this.$language.current + '_title_sort asc';
                    else formData.sort = t + '_' + this.$language.current + '_date_sort desc';
                    this.axios.post(this.apiURL + '/items/search', formData)
                        .then(response => {
                            // Get items in response
                            this.results[t] = response.data.content;

                            // Get total items and calculate pagination
                            this.formData.page.totals[t] = response.data.totalElements;

                            this.loading++;
                        })
                        .catch(error => {
                            this.processError(error);
                        });
                });
            },
            /**
             * Process query parameters
             */
            processParametersQuery() {
                // Get query parameters
                let paramValues = this.$route.params.paramValues.split('/');

                // Parse parameters to find values
                this.type = '';
                this.formData.text = '';
                this.formData.query = '';
                for (let i = 0; i < paramValues.length; i += 2) {
                    // Pagination parameter
                    if (paramValues[i] === 'sit') this.type = this.b64ToUTF8(paramValues[i + 1]);
                    // Text search parameter (base64)
                    else if (paramValues[i] === 't' || paramValues[i] === 'ost' ) {
                        if (paramValues[i] === 't') this.formData.text = this.b64ToUTF8(paramValues[i + 1]);
                        else this.formData.text = paramValues[i + 1];
                        this.$store.commit('setSearchText', this.formData.text.trim());

                        // Check if text starts with " (exact search)
                        if (this.formData.text.startsWith('"')) this.formData.query += ' +({type}_search:"' + this.formData.text.trim().replaceAll('"', '') + '")';
                        else {
                            // Separate text in words and do a search with OR
                            let words = this.formData.text.trim().split(' ');
                            words.filter(w => w.trim() != '').forEach(w => this.formData.query += ' +({type}_search:' + this.filterQuery(w) + ')');
                        }
                    }
                }
            },
            /**
             * Execute see all results
             */
            seeAll(type) {
                this.$router.push({ path: this.buildRoute([{ parameterName: 'sit', value: this.UTF8ToB64(type) }]) });
            }
        },
        watch: {
            /**
             * Monitoring route change to update parameters and search results
             */
            $route(from, to) {
                // If parameters changes, then re-init data
                if (from.params.paramValues !== to.params.paramValues) this.init();
            }
        }
    }
</script>