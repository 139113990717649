<!--
--------------------------------------------------------------------------
   NotFound.vue
--------------------------------------------------------------------------

    Component to build hot found screen

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="not-found" class="view">
        <h1 v-translate>Not found</h1>
        <translate>The page you requested was not found on the server.</translate>
    </div>
</template>

<script>
    export default {
        name: 'not-found',
        mounted() {
            // Set base path
            this.$store.commit('setCurrentPath', '/notFound');

            // Set metadata
            this.setMetadata(this.$gettext('Not found') + ' | ' + this.$gettext(this.$store.state.metadata.title), 'Page not found', this.$gettext('not found') + ',' + this.$gettext(this.$store.state.metadata.keywords));
        }
    };
</script>